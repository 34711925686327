import { AlertIcon } from '@shared/ui-components';

import { Button } from '../../components';

import styles from './error-content.module.scss';

export const ErrorPageContentClient = ({
  message,
  reset,
}: {
  message?: string;
  reset?: () => void;
}) => {
  const tryAgainLabel = 'Try again';

  return (
    <div className={styles['error-content']}>
      <AlertIcon className={styles['error-content__icon']} />
      <div className={styles['error-content__message']}>{message}</div>
      <div className={styles['error-content__actions']}>
        <Button onClick={() => reset}>{tryAgainLabel}</Button>
      </div>
    </div>
  );
};
